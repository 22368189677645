import { NoduleImageData } from '@entities/ImageData';
import { importData } from '@utilities/DataDecoder';

export const imageData: NoduleImageData[] = importData();

// Preload the images for seamless rendering
imageData.forEach(imageData => {
  const img = new Image();
  img.src = imageData.imageSrc;
});
