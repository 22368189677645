import React, { memo } from 'react';

interface StarsBackgroundLayersProps {
  prewarm?: boolean;
}

const StarsBackgroundLayers: React.FC<StarsBackgroundLayersProps> = ({
  prewarm,
}) => {
  return (
    <div id="layers">
      {[0, 1, 2, 3, 4].map(num => (
        <Layer key={num} limit={30} layerIndex={num} prewarm={prewarm} />
      ))}
    </div>
  );
};

export const StarsBackground = memo(StarsBackgroundLayers);

interface StarProps {
  left: string;
  top: string;
  scale: string;
  opacity: string;
}

const Star: React.FC<StarProps> = ({ left, top, scale, opacity }) => {
  return (
    <span
      className="star"
      style={{ left, top, transform: `scale(${scale})`, opacity }}
    >
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.744,5.372C7.777,5.372,5.372,2.967,5.372,0c0,2.967-2.405,5.372-5.372,5.372c2.967,0,5.372,2.405,5.372,5.372 C5.372,7.777,7.777,5.372,10.744,5.372z" />
      </svg>
    </span>
  );
};

interface LayerProps {
  layerIndex: number;
  limit: number;
  prewarm?: boolean;
}

const Layer: React.FC<LayerProps> = ({ limit, layerIndex, prewarm }) => {
  const stars = Array.from({ length: limit }, _ => ({
    left: randomInt(1, 100) + '%',
    top: randomInt(1, 100) + '%',
    scale: 'scale(' + randomNum(0, 1) + ')',
    opacity: randomNum(0.6, 1),
  }));

  return (
    <div
      className={`layer`}
      style={{ animationDelay: `${layerIndex * (prewarm ? -1 : 1)}s` }}
    >
      {stars.map((star, index) => (
        <Star key={index} {...star} />
      ))}
    </div>
  );
};

function randomInt(min: number, max: number) {
  return Math.floor(Math.random() * max + min);
}

function randomNum(min: number, max: number) {
  return Math.max(Math.random() * max, min).toFixed(1);
}
