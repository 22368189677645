import React from 'react';
import { GameSession } from '@entities/GameSession';
import Seemode from 'src/assets/images/seemode.svg';
import { Icon } from '../../Components/Icon';
import { GradingConfig } from '@interfaces/TiRadsGrading';
import { checkIfStringArraySame, padNumberWithZero } from '@utilities/String';
import { Pill } from 'src/app/Components/Pill';
import { FrameButton, FrameFooter } from 'src/app/Components/Frame';
import { DicomImage } from 'src/app/Components/DicomImage';

export interface ResultDetailProps {
  setImageId: React.Dispatch<React.SetStateAction<string | undefined>>;
  gameSession: GameSession;
  imageId?: string;
}

export const ResultDetail = ({
  setImageId,
  gameSession,
  imageId,
}: ResultDetailProps) => {
  const image = gameSession.shownImages.find(image => image.id == imageId);
  if (!image) return;
  const imageIndex = gameSession.shownImages
    .map(image => image.id)
    .indexOf(image.id);
  const consensusFeatures = image.groundTruth?.characteristics;
  const consensusGrading = image.groundTruth?.grading;
  const finding = image.modelResult?.findings[0];
  const modelGrading = finding?.grading;

  const userSelection = gameSession.selectionHistory.find(
    selection => selection.dicomId === image.id
  );

  return (
    <>
      <div className="flex text-left mb-8 py-3">
        <h2 className="text-display1 text-white py-2">
          Image {padNumberWithZero(imageIndex + 1)}
          {userSelection?.grading &&
            ` - You selected ${GradingConfig[userSelection.grading].label}`}
          {userSelection?.grading &&
            userSelection?.grading == consensusGrading && (
              <Icon
                name="check"
                className="text-neutral-50 w-[42px] h-[42px] pl-4"
              />
            )}
        </h2>
      </div>

      <div className="grid gap-4 grid-cols-resultDetail grid-rows-[minmax(57px,_auto)]">
        <div className="row-span-2">
          <DicomImage image={image} />
        </div>
        <div className="bg-neutral-400 rounded-t-lg h-[57px] grid justify-center items-center">
          <img src={Seemode} className="inline" />
        </div>
        <div className="bg-primary rounded-t-lg text-neutral-900 text-center font-bold text-display3 py-[12px] h-[57px]">
          <Icon
            name="consensus"
            className="text-black w-[32px] h-[32px] pr-2"
          />
          Consensus
        </div>

        <div className="detail-table grid col-span-2 grid-cols-[minmax(auto,_200px)_minmax(auto,_175px)] gap-x-4 grid-rows-[repeat(5,_max-content)_auto]">
          <div className="bg-neutral-400/20 grid justify-end grid-cols-[auto_30px] pt-4 px-4">
            <div className="flex items-center justify-center bg-neutral-200/40 rounded-lg w-[80px] h-[36px] text-neutral-50 text-display6">
              {modelGrading && GradingConfig[modelGrading].label}
            </div>
            {modelGrading === consensusGrading && (
              <Icon
                name="check"
                className="text-neutral-50 w-[24px] h-[24px] self-center justify-self-end"
              />
            )}
          </div>

          <div className="bg-primary/10 pt-4 px-4">
            <div className="flex items-center justify-center bg-primary/10 rounded-lg  w-[80px] h-[36px] text-primary text-display6">
              {consensusGrading && GradingConfig[consensusGrading].label}
            </div>
          </div>

          <DetailTableCell>
            <div className="border-t border-neutral-50 grid justify-end grid-cols-[auto_30px] gap-y-3 pt-4">
              <Pill
                text={finding?.characteristics.composition}
                className="bg-neutral-200 justify-self-end"
              />
              {finding?.characteristics.composition ==
              consensusFeatures?.composition ? (
                <Icon
                  name="check"
                  className="text-neutral-50 w-[24px] h-[24px] self-center justify-self-end"
                />
              ) : (
                <div></div>
              )}
            </div>
          </DetailTableCell>

          <DetailTableCell>
            <div className="border-t border-primary grid justify-end grid-cols-1 pt-4">
              <Pill
                text={consensusFeatures?.composition}
                className="bg-primary"
              />
            </div>
          </DetailTableCell>

          <DetailTableCell>
            <div className="grid justify-end grid-cols-[auto_30px] pb-[0px]">
              <Pill
                text={finding?.characteristics.echogenicity}
                className="bg-neutral-200 justify-self-end"
              />
              {finding?.characteristics.echogenicity ==
              consensusFeatures?.echogenicity ? (
                <Icon
                  name="check"
                  className="text-neutral-50 w-[24px] h-[24px] self-center justify-self-end"
                />
              ) : (
                <div></div>
              )}
            </div>
          </DetailTableCell>

          <DetailTableCell>
            <Pill
              text={consensusFeatures?.echogenicity}
              className="bg-primary"
            />
          </DetailTableCell>

          <DetailTableCell>
            <div className="grid justify-end grid-cols-[auto_30px]">
              <Pill
                text={finding?.characteristics.shape}
                className="bg-neutral-200 justify-self-end"
              />
              {finding?.characteristics.shape == consensusFeatures?.shape ? (
                <Icon
                  name="check"
                  className="text-neutral-50 w-[24px] h-[24px] self-center justify-self-end"
                />
              ) : (
                <div></div>
              )}
            </div>
          </DetailTableCell>

          <DetailTableCell>
            <Pill text={consensusFeatures?.shape} className="bg-primary" />
          </DetailTableCell>

          <DetailTableCell>
            <div className="grid justify-end grid-cols-[auto_30px]">
              <Pill
                text={finding?.characteristics.margin}
                className="bg-neutral-200 justify-self-end"
              />
              {finding?.characteristics.margin == consensusFeatures?.margin ? (
                <Icon
                  name="check"
                  className="text-neutral-50 w-[24px] h-[24px] self-center justify-self-end"
                />
              ) : (
                <div></div>
              )}
            </div>
          </DetailTableCell>

          <DetailTableCell>
            <Pill text={consensusFeatures?.margin} className="bg-primary" />
          </DetailTableCell>

          {finding?.characteristics.echogenicFoci.map((value, index) => {
            if (!value) return null;
            return (
              <>
                <DetailTableCell key={index}>
                  <div className="grid justify-end grid-cols-[auto_30px] pb-4">
                    <Pill
                      text={value}
                      className="bg-neutral-200 justify-self-end"
                    />
                    {checkIfStringArraySame(
                      finding?.characteristics.echogenicFoci,
                      consensusFeatures?.echogenicFoci
                    ) && (
                      <Icon
                        name="check"
                        className="text-neutral-50 w-[24px] h-[24px] self-center justify-self-end"
                      />
                    )}
                  </div>
                </DetailTableCell>

                {consensusFeatures?.echogenicFoci.map((value, index) => {
                  return (
                    <DetailTableCell key={index}>
                      <div className="pb-4">
                        {value && <Pill text={value} className="bg-primary" />}
                      </div>
                    </DetailTableCell>
                  );
                })}
              </>
            );
          })}
        </div>
      </div>

      <FrameFooter>
        <FrameButton variant="neutral" onClick={() => setImageId(undefined)}>
          <Icon
            name="arrow-left"
            className="text-neutral-50 w-[24px] h-[24px]"
          />
          Back
        </FrameButton>
      </FrameFooter>
    </>
  );
};

const DetailTableCell = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="pl-2 pr-2 pt-2 odd:bg-neutral-400/20 even:bg-primary/10 auto-rows-max">
      {children}
    </div>
  );
};
