import { motion } from 'framer-motion';
import React from 'react';
import { Particles } from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { Engine, RecursivePartial, IOptions } from 'tsparticles-engine';

const init = async (engine: Engine) => {
  await loadFull(engine);
};

const particleOptions: RecursivePartial<IOptions> = {
  fullScreen: {
    zIndex: 1,
  },
  particles: {
    number: {
      value: 0,
    },
    shape: {
      type: 'image',
      image: [
        {
          src: 'stars/star_1.svg',
        },
        {
          src: 'stars/star_2.svg',
        },
        {
          src: 'stars/star_3.svg',
        },
        {
          src: 'stars/star_4.svg',
        },
      ],
    },
    opacity: {
      value: 1,
      animation: {
        enable: true,
        minimumValue: 0,
        speed: {
          min: 1,
          max: 2,
        },
        startValue: 'max',
        destroy: 'min',
        sync: true,
      },
    },
    size: {
      value: 32,
      random: {
        enable: true,
        minimumValue: 2.5,
      },
    },
    links: {
      enable: false,
    },
    life: {
      duration: {
        value: 1.5,
      },
      count: 1,
    },
    move: {
      enable: true,
      gravity: {
        enable: true,
        acceleration: 0,
      },
      speed: {
        min: 10,
        max: 35,
      },
      decay: 0.07,
      angle: 360,
      direction: 'none',
      straight: false,
      outModes: {
        default: 'destroy',
        top: 'none',
      },
    },
    rotate: {
      value: {
        min: 0,
        max: 360,
      },
      direction: 'random',
      move: true,
      animation: {
        enable: true,
        speed: 15,
      },
    },
  },
  emitters: {
    position: {
      x: 80,
      y: 40,
    },
    life: {
      count: 1,
      duration: 0.1,
    },
    rate: {
      quantity: 25,
    },
    size: {
      width: 0,
      height: 0,
    },
  },
};

interface ScoreParticlesProps {
  score: number;
}

export const ScoreParticles: React.FC<ScoreParticlesProps> = ({ score }) => {
  return (
    <>
      <motion.div
        className="fixed top-[40%] left-[80%] -translate-x-1/2 -translate-y-1/2 text-neutral-50 text-display2 font-medium z-50"
        initial={{ opacity: 1 }}
        animate={{
          opacity: 0,
          transition: { duration: 0.3, delay: 0.7, ease: 'easeOut' },
        }}
      >
        + {score} points
      </motion.div>
      <Particles init={init} options={particleOptions} />
    </>
  );
};
