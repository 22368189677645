import trophy1 from 'src/assets/images/trophy-1st.svg';
import trophy2 from 'src/assets/images/trophy-2nd.svg';
import trophy3 from 'src/assets/images/trophy-3rd.svg';

export const GameConfig = {
  numberOfImages: 10,
  imageTimeLimitInSeconds: 30,
  maxScorePerImage: 300,
  imageAnimationTime: 2,
};

export const RankConfig: Record<
  number,
  { label: string; color: string; image: string }
> = {
  1: {
    label: '1st',
    color: '#F8D73A',
    image: trophy1,
  },
  2: {
    label: '2nd',
    color: '#C2B9B7',
    image: trophy2,
  },
  3: {
    label: '3rd',
    color: '#C96A2C',
    image: trophy3,
  },
};
