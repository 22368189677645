export const padNumberWithZero = (num: number) => {
  return String(num).padStart(2, '0');
};

export const checkIfStringArraySame = (
  arrayA?: string[],
  arrayB?: string[]
) => {
  if (!arrayA && !arrayB) return true;
  if (!arrayA || !arrayB) return false;

  if (arrayA.length != arrayB.length) return false;

  arrayA.sort();
  arrayB.sort();
  return JSON.stringify(arrayA) == JSON.stringify(arrayB);
};
