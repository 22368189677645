import React from 'react';
import { GradingConfig, TiRadsGrading } from '@interfaces/TiRadsGrading';
import { Icon } from 'src/app/Components/Icon';
import { UserSelection } from '@interfaces/GameSession';
import { NoduleImageData } from '@interfaces/ImageData';
import { formatMilliseconds } from '@utilities/TimeFormat';
import { padNumberWithZero } from '@utilities/String';

export interface ResultTableRowProps {
  index: number;
  image: NoduleImageData;
  selectionHistory: UserSelection[];
  setImageId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const ResultTableRow = ({
  index,
  image,
  selectionHistory,
  setImageId,
}: ResultTableRowProps) => {
  const modelResultGrading = image.modelResult?.findings[0]
    .grading as TiRadsGrading;
  const consensusGrading = image.groundTruth?.grading as TiRadsGrading;
  const userSelection = selectionHistory.find(
    history => history.dicomId == image.id
  );

  return (
    <div
      key={image.id}
      className="grid-cols-result grid gap-4 border-t-small border-neutral-400"
    >
      <div className="text-center">
        <span className="text-neutral-300 text-display4 pr-4">
          {padNumberWithZero(index)}
        </span>
        <img
          src={image.imageSrc}
          className=" inline rounded-md max-h-[55px] my-2"
          onClick={() => setImageId(image.id)}
        />
      </div>

      <div className="bg-neutral-400/20 grid justify-items-center items-center grid-cols-[2fr_1fr_1fr] gap-3">
        <div className="justify-self-start pl-8">
          <Icon name="clock" className="text-neutral-300 w-[24px] h-[24px]" />
          <span>{formatMilliseconds(image.modelResult?.usedTime || 0)}</span>
        </div>
        <span className="font-bold">
          {GradingConfig[modelResultGrading].label}
        </span>
        {modelResultGrading === consensusGrading ? (
          <Icon name="check" className="text-neutral-50 w-[24px] h-[24px]" />
        ) : null}
      </div>

      <div className="bg-primary/10 grid justify-items-center items-center">
        <span className="text-primary font-bold">
          {GradingConfig[consensusGrading].label}
        </span>
      </div>

      <div className="bg-neutral-400/20 grid justify-items-center items-center grid-cols-[1fr_1fr_2fr] gap-3">
        {userSelection?.grading === consensusGrading ? (
          <Icon name="check" className="text-neutral-50 w-[24px] h-[24px]" />
        ) : (
          <div></div>
        )}
        <span className="font-bold">
          {userSelection?.grading
            ? GradingConfig[userSelection?.grading].label
            : '-'}
        </span>
        <div className="justify-self-start pl-6">
          <Icon name="clock" className="text-neutral-300 w-[24px] h-[24px]" />
          <span>{formatMilliseconds(userSelection?.usedTime || 0)}</span>
        </div>
      </div>

      <div className="grid justify-items-center items-center">
        <button
          className="rounded-3xl bg-neutral-400 min-h-[32px] px-5"
          onClick={() => setImageId(image.id)}
        >
          <Icon
            name="cross-hair"
            className="text-neutral-50 w-[24px] h-[24px] pr-3"
          />
          <span className="text-display6">inspect</span>
        </button>
      </div>
    </div>
  );
};
