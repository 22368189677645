import React, { useEffect, useState } from 'react';
import { GameConfig } from 'src/config/GameConfig';
import clock from 'src/assets/images/clock.svg';
import { motion } from 'framer-motion';

interface TimerProps {
  startTime: Date;
  onLimitReached(): void;
}

export const Timer: React.FC<TimerProps> = props => {
  return (
    <motion.div
      className="fixed top-[40%] left-[80%] -translate-x-1/2 -translate-y-1/2 z-50 flex items-center justify-center w-[12vh] h-[12vh]"
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          duration: 1,
          ease: 'easeOut',
        },
      }}
    >
      <TimerCircle {...props} />
      <img className="absolute h-[37.5%]" src={clock} />
      <TimerText {...props} />
    </motion.div>
  );
};

const TimerText: React.FC<TimerProps> = ({ startTime, onLimitReached }) => {
  const [remainingTimeS, setRemainingTimeS] = useState(
    GameConfig.imageTimeLimitInSeconds
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const time =
        GameConfig.imageTimeLimitInSeconds -
        (new Date().getTime() - startTime.getTime()) / 1000;
      if (time <= 0) {
        onLimitReached();
      } else {
        setRemainingTimeS(time);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [startTime, onLimitReached]);

  return (
    <span className="block absolute bottom-[22%] translate-y-1/2 text-black font-semibold">
      {remainingTimeS.toFixed(0)}&nbsp;s
    </span>
  );
};

const TimerCircle: React.FC<TimerProps> = ({ startTime }) => {
  const [elapsedTimeMs, setElapsedTimeMs] = useState(0);

  const percentage = elapsedTimeMs / (GameConfig.imageTimeLimitInSeconds * 10);

  const progressStyle = {
    background: `conic-gradient(#00FFBA8C 0% 0%, #00FFBA66 ${
      percentage - 0.2
    }% ${percentage}%, #00FFBA ${percentage + 0.1}% 100%)`,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTimeMs(new Date().getTime() - startTime.getTime());
    }, 50);
    return () => clearInterval(interval);
  }, [startTime]);

  return (
    <div className="w-full h-full rounded-full" style={progressStyle}></div>
  );
};
