import { PlayerInfo } from '@entities/PlayerInfo';
import { formatMilliseconds } from './TimeFormat';

export function formatToCSV(players: PlayerInfo[]): string {
  // Define CSV headers
  const headers = [
    'ID',
    'First Name',
    'Last Name',
    'Institution',
    'Email',
    'Score',
    'Total Time',
    'Selection History',
  ].join(',');

  // Format player data
  const rows = players.map(player => {
    const selectionHistoryJSON = JSON.stringify(player.selectionHistory);
    return [
      player.id,
      player.firstName,
      player.lastName,
      player.institution,
      player.email,
      player.score,
      formatMilliseconds(player.totalTime),
      selectionHistoryJSON,
    ]
      .map(escapeCSVField)
      .join(',');
  });

  // Join headers and rows to form the CSV
  return [headers, ...rows].join('\n');
}

function escapeCSVField(field?: string | number) {
  if (typeof field === 'undefined' || field === null) return '';
  const str = field.toString();
  if (str.includes(',') || str.includes('\n') || str.includes('"')) {
    return `"${str.replace(/"/g, '""')}"`;
  }
  return str;
}
