import React from 'react';
import { GameStatus } from '@interfaces/GameStatus';
import UltrasoundImage from './icons/UltrasoundImage.svg';
import Trophy from 'src/assets/images/trophy.svg';
import { GradingConfig, GradingKeys } from '@entities/TiRadsGrading';
import { Button } from './ButtonBar';
import {
  Frame,
  FrameButton,
  FrameContent,
  FrameFooter,
  FrameHeader,
} from '../Components/Frame';
import { Icon } from '../Components/Icon';
export interface GameInstructionsProps {
  setGameStatus: React.Dispatch<React.SetStateAction<GameStatus>>;
}

export const GameInstruction = ({ setGameStatus }: GameInstructionsProps) => {
  return (
    <Frame>
      <FrameHeader>Game Instructions</FrameHeader>
      <FrameContent>
        <div className="overflow-y-auto sm:-mx-2 lg:-mx-4">
          <div className="flex flex-col min-w-full py-2 sm:px-6 lg:px-4 gap-16">
            <div className="flex flex-col overflow-hidden gap-10">
              <div className="flex items-center text-display3">
                <h2>1- Select the correct TIRAD level of 10 DICOM images.</h2>
              </div>
              <div className="flex gap-6 items-center px-10">
                {Array(5)
                  .fill(UltrasoundImage)
                  .map((s, i) => {
                    return <img key={i} src={s} />;
                  })}
                <div className="flex text-display3">
                  <h2> = </h2>
                </div>
                <svg
                  width="44"
                  height="44"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Frame">
                    <path
                      id="Vector"
                      d="M12.3569 2L15.4469 8.26L22.3569 9.27L17.3569 14.14L18.5369 21.02L12.3569 17.77L6.17693 21.02L7.35693 14.14L2.35693 9.27L9.26693 8.26L12.3569 2Z"
                      stroke="#00ffba"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div className="flex flex-col overflow-hidden gap-10">
              <div className="flex items-center text-display3">
                <h2>
                  2- You’ve got 30 seconds per image. The quicker you choose,
                  the more points you get.
                </h2>
              </div>
              <div className="flex gap-6 items-center px-10">
                {GradingKeys.map(grading => (
                  <Button
                    key={grading}
                    label={GradingConfig[grading].label}
                    className={GradingConfig[grading].className}
                    onSelect={() => null}
                  />
                ))}
                <div className="flex text-display3">
                  <h2> = </h2>
                </div>

                <svg
                  width="44"
                  height="44"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Frame">
                    <path
                      id="Vector"
                      d="M10.5713 2H14.5713"
                      stroke="#00ffba"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      id="Vector_2"
                      d="M12.5713 14L15.5713 11"
                      stroke="#00ffba"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      id="Vector_3"
                      d="M12.5713 22C16.9896 22 20.5713 18.4183 20.5713 14C20.5713 9.58172 16.9896 6 12.5713 6C8.15301 6 4.57129 9.58172 4.57129 14C4.57129 18.4183 8.15301 22 12.5713 22Z"
                      stroke="#00ffba"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div className="flex flex-col overflow-hidden gap-6">
              <div className="flex items-center text-display3">
                <h2> 3- Highest scores will show on the leaderboard.</h2>
              </div>
              <div className="flex gap-6 items-center px-10">
                <svg
                  width="44"
                  height="44"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Frame">
                    <path
                      id="Vector"
                      d="M12.3569 2L15.4469 8.26L22.3569 9.27L17.3569 14.14L18.5369 21.02L12.3569 17.77L6.17693 21.02L7.35693 14.14L2.35693 9.27L9.26693 8.26L12.3569 2Z"
                      stroke="#00ffba"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
                <div className="flex text-display3">
                  <h2> + </h2>
                </div>
                <svg
                  width="44"
                  height="44"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Frame">
                    <path
                      id="Vector"
                      d="M10.5713 2H14.5713"
                      stroke="#00ffba"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      id="Vector_2"
                      d="M12.5713 14L15.5713 11"
                      stroke="#00ffba"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      id="Vector_3"
                      d="M12.5713 22C16.9896 22 20.5713 18.4183 20.5713 14C20.5713 9.58172 16.9896 6 12.5713 6C8.15301 6 4.57129 9.58172 4.57129 14C4.57129 18.4183 8.15301 22 12.5713 22Z"
                      stroke="#00ffba"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
                <div className="flex text-display3">
                  <h2> = </h2>
                </div>
                <img src={Trophy} />
              </div>
            </div>
          </div>
        </div>
      </FrameContent>
      <FrameFooter>
        <FrameButton onClick={() => setGameStatus(GameStatus.Leaderboard)}>
          <Icon name="arrow-left" />
          Back
        </FrameButton>
        <FrameButton
          variant="primary"
          onClick={() => setGameStatus(GameStatus.Started)}
        >
          <Icon name="controller" />
          Begin
        </FrameButton>
      </FrameFooter>
    </Frame>
  );
};
