import { NoduleImageData } from '@entities/ImageData';
import { GradingConfig, TiRadsGrading } from '@entities/TiRadsGrading';
import {
  AnimatePresence,
  motion,
  useAnimate,
  useWillChange,
} from 'framer-motion';
import React, { useEffect } from 'react';
import background from 'src/assets/images/background.svg';
import { GameConfig } from 'src/config/GameConfig';
import { DicomImage } from '../Components/DicomImage';

interface ImageContainerProps {
  image: NoduleImageData;
  lastSelected: TiRadsGrading | undefined;
}

export const ImageContainer: React.FC<ImageContainerProps> = ({
  image,
  lastSelected,
}) => {
  return (
    <div className="fixed top-0 w-full h-full flex flex-col items-center gap-xxxxl z-20">
      <AnimatePresence>
        {!!image && (
          <Image image={image} key={image.id} lastSelected={lastSelected} />
        )}
      </AnimatePresence>
      <img src={background} className="fixed top-0 h-[90%] max-w-none" />
    </div>
  );
};

interface ImageProps {
  image: NoduleImageData;
  lastSelected: TiRadsGrading | undefined;
}

export const Image: React.FC<ImageProps> = ({ image, lastSelected }) => {
  const willChange = useWillChange();
  const [scope, animate] = useAnimate();

  const exitBorderColor = lastSelected
    ? GradingConfig[lastSelected].color
    : 'blueviolet';

  useEffect(() => {
    (async () => {
      await animate(
        scope.current,
        {
          opacity: 1,
          y: '20%',
          scale: 0.8,
        },
        {
          opacity: {
            duration: 0.8,
            ease: 'easeOut',
          },
          y: {
            duration: 2,
            ease: 'easeOut',
          },
          scale: {
            duration: 2,
            ease: 'easeIn',
          },
        }
      );
      if (scope.current) {
        await animate(
          scope.current,
          {
            scale: 1,
          },
          {
            duration: GameConfig.imageTimeLimitInSeconds - 2,
            ease: 'easeOut',
          }
        );
      }
    })();
  }, [scope, animate]);

  return (
    <motion.div
      ref={scope}
      style={{ willChange }}
      className="absolute z-10 w-auto h-[55%]"
      initial={{ opacity: 0, y: '-75%', scale: 0.5 }}
      exit={{
        opacity: 0,
        scale: 1.5,
        y: '150%',
        transition: {
          duration: 0.5,
          ease: 'easeOut',
        },
      }}
    >
      <motion.div
        className="border-large border-primary h-full rounded-s overflow-hidden"
        exit={{
          borderColor: exitBorderColor,
          transition: {
            duration: 0.1,
          },
        }}
      >
        <DicomImage image={image} delayBoundingBox />
      </motion.div>
    </motion.div>
  );
};
