export interface NoduleCharacteristics {
  composition: string;
  echogenicity: string;
  shape: string;
  margin: string;
  echogenicFoci: string[];
}

export const noduleCompositionToString = (composition?: string) => {
  switch (composition) {
    case 'solid_or_almost_completely_solid':
      return 'Solid or almost completely solid';

    case 'mixed_cystic_&_solid':
      return 'Mixed cystic and solid';

    case 'cystic/almost_completely_cystic':
      return 'Cystic or almost completely cystic';

    case 'spongiform':
      return 'Spongiform';

    default:
      return composition || '';
  }
};

export const noduleEchogenicityToString = (echogenicity?: string) => {
  switch (echogenicity) {
    case 'anechoic':
      return 'Anechoic';

    case 'hyperechoic_or_isoechoic':
      return 'Hyperechoic or isoechoic';

    case 'hypoechoic':
      return 'Hypoechoic';

    case 'very_hypoechoic':
      return 'Very hypoechoic';

    default:
      return echogenicity || '';
  }
};

export const noduleShapeToString = (shape?: string) => {
  switch (shape) {
    case 'wider_than_tall':
      return 'Wider than tall';

    case 'taller_than_wide':
      return 'Taller than wide';
    default:
      return shape || '';
  }
};

export const noduleMarginToString = (margin?: string) => {
  switch (margin) {
    case 'smooth':
      return 'Smooth';

    case 'ill-defined':
    case 'ill defined':
      return 'Ill defined';

    case 'lobulated_or_irregular':
      return 'Lobulated or irregular';

    case 'extra_thyroidal_extension':
      return 'Extra thyroidal extension';
    default:
      return margin || '';
  }
};

export const noduleEchogenicFociToString = (echogenicFoci?: string[]) => {
  return (
    echogenicFoci?.map(value => {
      switch (value) {
        case 'macrocalcifications':
          return 'Macrocalcifications';

        case 'peripheral_(rim)_calcification':
          return 'Peripheral (rim) calcifications';

        case 'punctate_echogenic_foci':
          return 'Punctate echogenic foci';

        case 'none_or_large_comet_tail_artefacts':
        case 'none_or_large_comet-tail_artefacts':
        default:
          return 'None or large comet-tail artefacts';
      }
    }) || ['None or large comet-tail artefacts']
  );
};
