import { PlayerInfo } from '@interfaces/PlayerInfo';

export const playerInfoKey = 'sm_playerInfo';

export const getPlayerInfo = (): PlayerInfo[] => {
  const playerInfoStr = localStorage.getItem(playerInfoKey);
  if (!playerInfoStr) {
    return [];
  }

  const parsedPlayerInfo = JSON.parse(playerInfoStr) as PlayerInfo[];
  if (!Array.isArray(parsedPlayerInfo)) {
    throw new Error('Invalid player info in local storage');
  }
  return parsedPlayerInfo;
};

export const savePlayerInfo = async (newPlayerInfo: PlayerInfo) => {
  const playerInfo = getPlayerInfo();

  playerInfo.push(newPlayerInfo);
  localStorage.setItem(playerInfoKey, JSON.stringify(playerInfo));
};
