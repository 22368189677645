export enum TiRadsGrading {
  TR1 = 'ti_rads_grading--tr1',
  TR2 = 'ti_rads_grading--tr2',
  TR3 = 'ti_rads_grading--tr3',
  TR4 = 'ti_rads_grading--tr4',
  TR5 = 'ti_rads_grading--tr5',
}

export const GradingConfig = {
  [TiRadsGrading.TR1]: {
    label: 'TR 1',
    color: '#c9d4da',
    className: 'bg-noduleSeverity-100',
    keyBinding: '1',
  },
  [TiRadsGrading.TR2]: {
    label: 'TR 2',
    color: '#ffd990',
    className: 'bg-noduleSeverity-300',
    keyBinding: '2',
  },
  [TiRadsGrading.TR3]: {
    label: 'TR 3',
    color: '#ffa048',
    className: 'bg-noduleSeverity-500',
    keyBinding: '3',
  },
  [TiRadsGrading.TR4]: {
    label: 'TR 4',
    color: '#f85e1d',
    className: 'bg-noduleSeverity-700',
    keyBinding: '4',
  },
  [TiRadsGrading.TR5]: {
    label: 'TR 5',
    color: '#e62c4c',
    className: 'bg-noduleSeverity-900',
    keyBinding: '5',
  },
} as const;

export const GradingKeys = Object.keys(GradingConfig) as TiRadsGrading[];

export function getGradingForKeyBinding(
  keyBinding: string
): TiRadsGrading | null {
  for (const [key, value] of Object.entries(GradingConfig)) {
    if (value.keyBinding === keyBinding) {
      return key as TiRadsGrading;
    }
  }
  return null;
}
