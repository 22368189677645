import { NoduleImageData } from '@entities/ImageData';
import { TiRadsGrading } from '@entities/TiRadsGrading';
import { GameConfig } from 'src/config/GameConfig';

export function calculateScore(
  elapsedTimeMs: number,
  grading: TiRadsGrading | undefined,
  image: NoduleImageData
): number {
  const isCorrect = !!grading && grading === image.groundTruth?.grading;
  if (isCorrect) {
    return GameConfig.maxScorePerImage - Math.floor(elapsedTimeMs / 100);
  } else {
    return 0;
  }
}
