import React from 'react';
import { useSound } from 'use-sound';
import frameSVG from 'src/assets/images/frame.svg';
import buttonSound from 'src/assets/sounds/SoundFX3-AnyButton.mp3';

interface FrameProps {
  children: React.ReactNode;
}

export const Frame: React.FC<FrameProps> = ({ children }) => {
  return (
    <div
      className="fixed top-[5%] bottom-[5%] left-1/2 -translate-x-1/2 flex justify-stretch items-stretch bg-opacity-70 aspect-[4/3] pt-[9vh] pb-[8vh] px-[12vh]"
      style={{
        backgroundImage: `url(${frameSVG})`,
      }}
    >
      <div className="flex-grow flex flex-col gap-4">{children}</div>
    </div>
  );
};

interface FrameHeaderProps {
  variant?: 'primary' | 'neutral';
  children: React.ReactNode;
}

export const FrameHeader: React.FC<FrameHeaderProps> = ({
  children,
  variant = 'neutral',
}) => {
  const variantClassName =
    variant === 'primary'
      ? 'bg-primary text-neutral-900'
      : 'bg-neutral-400 text-white';
  return (
    <header
      className={`flex items-center justify-center rounded-2xl text-center mb-8 ${variantClassName}`}
    >
      <h2 className="text-display1 font-semibold tracking-wide flex items-center p-2">
        {children}
      </h2>
    </header>
  );
};

export const FrameContent: React.FC<FrameProps> = ({ children }) => {
  return <main className=" max-h-[60vh]">{children}</main>;
};

export const FrameFooter: React.FC<FrameProps> = ({ children }) => {
  return (
    <footer className="flex justify-center gap-10 mt-auto">{children}</footer>
  );
};

interface FrameButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  variant?: 'primary' | 'neutral';
}

export const FrameButton: React.FC<FrameButtonProps> = ({
  children,
  variant = 'neutral',
  type = 'button',
  onClick,
  ...rest
}) => {
  const [play] = useSound(buttonSound, { interrupt: true });
  const variantClassName =
    variant === 'primary'
      ? 'bg-primary text-neutral-900'
      : 'bg-neutral-400 text-neutral-100';
  return (
    <button
      className={`inline-flex rounded-xl py-4 px-5 font-bold ease-out hover:brightness-110 gap-2 ${variantClassName}`}
      type={type}
      onClick={event => {
        play();
        onClick?.(event);
      }}
      {...rest}
    >
      {children}
    </button>
  );
};
