import {
  GradingConfig,
  GradingKeys,
  TiRadsGrading,
  getGradingForKeyBinding,
} from '@entities/TiRadsGrading';
import React, { useEffect, useState } from 'react';
import panel from 'src/assets/images/panel.svg';
import { Icon } from '../Components/Icon';
import { formatMilliseconds } from '@utilities/TimeFormat';
import { useSound } from 'use-sound';
import buttonSound from 'src/assets/sounds/SoundFX1-TRbutton.mp3';

interface ButtonBarProps {
  startTime: Date;
  totalScore: number;
  onSelect(grading: TiRadsGrading): void;
  isActive: boolean;
  correctAnswer: TiRadsGrading | undefined;
}

export const ButtonBar: React.FC<ButtonBarProps> = ({
  onSelect,
  totalScore,
  startTime,
  isActive,
  correctAnswer,
}) => {
  const [showCorrect, setShowCorrect] = useState(false);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const keySelected = getGradingForKeyBinding(event.key);
      if (keySelected) {
        onSelect(keySelected);
      } else if (event.key === 'x' || event.key === 'X') {
        setShowCorrect(true);
      }
    };

    window.addEventListener('keypress', handleKeyPress);
    return () => window.removeEventListener('keypress', handleKeyPress);
  }, [onSelect]);

  return (
    <div
      className="fixed z-50 bottom-[-.5vh] h-[15.5vh] aspect-[10/1] left-1/2 -translate-x-1/2 bg-top bg-cover bg-no-repeat"
      style={{
        backgroundImage: `url(${panel})`,
      }}
    >
      <div className="relative h-full w-[calc(100%-8rem)] max-w-[100vw] left-1/2 -translate-x-1/2">
        <div className="absolute z-40 top-0 py-8 left-1/2 -translate-x-1/2 flex gap-12">
          {GradingKeys.map(grading => (
            <Button
              key={grading}
              label={GradingConfig[grading].label}
              className={GradingConfig[grading].className}
              onSelect={() => onSelect(grading)}
              highlighted={showCorrect && grading === correctAnswer}
            />
          ))}
        </div>
        <GlobalTimer startTime={startTime} isActive={isActive} />
        <Score totalScore={totalScore} />
      </div>
    </div>
  );
};

interface ButtonProps {
  label: string;
  className: string;
  onSelect(): void;
  highlighted?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  label,
  className,
  onSelect,
  highlighted,
}) => {
  const [play] = useSound(buttonSound, { interrupt: true });
  const highlightedClass = highlighted
    ? 'outline-primary outline-1 outline-double'
    : '';
  return (
    <button
      className={`rounded-lg text-black font-bold p-xs ${className} ${highlightedClass}`}
      onClick={e => {
        onSelect();
        play();
        e.currentTarget.blur();
      }}
      tabIndex={-1}
    >
      {label}
    </button>
  );
};

interface GlobalTimerProps {
  startTime: Date;
  isActive: boolean;
}

const GlobalTimer: React.FC<GlobalTimerProps> = ({ startTime, isActive }) => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isActive) {
        setTime(new Date().getTime() - startTime.getTime());
      }
    }, 100);
    return () => clearInterval(interval);
  }, [startTime, isActive]);

  return (
    <div className="absolute bottom-[1%] z-50 left-0 px-8 py-[2vh] flex items-center">
      <Icon name="clock" className="h-14 m-4 text-neutral-300" />
      <span className="text-neutral-50 text-display2 font-medium">
        {formatMilliseconds(time)}
      </span>
    </div>
  );
};

interface ScoreProps {
  totalScore: number;
}

const Score: React.FC<ScoreProps> = ({ totalScore }) => {
  return (
    <div className="absolute bottom-[1%] z-50 right-0 px-8 py-[2vh] flex items-center w-64">
      <Icon name="star" className="h-14 w-14 m-4 text-primary" />
      <span className="text-neutral-50 text-display2 font-medium">
        {totalScore}
      </span>
    </div>
  );
};
