import { UserSelection } from '@entities/GameSession';
import { GradingConfig } from '@entities/TiRadsGrading';
import React, { useEffect, useState } from 'react';
import { Icon } from '../Components/Icon';
import { GameConfig } from 'src/config/GameConfig';
import { formatMilliseconds } from '@utilities/TimeFormat';

interface SelectionViewProps {
  selectionHistory: UserSelection[];
  currentIndex: number;
  startTime: Date;
}

export const SelectionView: React.FC<SelectionViewProps> = ({
  selectionHistory,
  currentIndex,
  startTime,
}) => {
  return (
    <div className="fixed w-[300px] pt-xxxl pl-xxl flex flex-col">
      {selectionHistory.map((selection, i) => (
        <SelectionRow
          index={i}
          key={i}
          selection={selection}
          startTime={startTime}
        />
      ))}
      {new Array(GameConfig.numberOfImages - currentIndex)
        .fill(null)
        .map((_, i) => (
          <SelectionRow
            index={i + currentIndex}
            key={i + currentIndex}
            selection={null}
            isCurrent={i === 0}
            startTime={startTime}
          />
        ))}
    </div>
  );
};

interface SelectionRowProps {
  index: number;
  selection: UserSelection | null;
  isCurrent?: boolean;
  startTime: Date;
}

export const SelectionRow: React.FC<SelectionRowProps> = ({
  index,
  selection,
  isCurrent,
  startTime,
}) => {
  const [time, setTime] = useState(selection?.usedTime ?? 0);

  useEffect(() => {
    if (isCurrent) {
      const interval = setInterval(() => {
        const elapsed = new Date().getTime() - startTime.getTime();
        setTime(elapsed);
      }, 100);
      return () => clearInterval(interval);
    }
  }, [startTime, isCurrent]);

  const borderClassName = isCurrent ? 'outline outline-primary' : '';
  const textClassName = selection
    ? 'text-neutral-300'
    : isCurrent
    ? 'text-neutral-50'
    : 'text-neutral-400';

  return (
    <div
      className={`flex items-start font-light py-1 px-6 rounded-full ${borderClassName} ${textClassName}`}
    >
      <div className="w-[38%]">{getImageLabel(index)}</div>
      <div className="w-[45%]">
        <Icon name="clock" className="h-9" />
        <span>{formatMilliseconds(time)}</span>
      </div>
      <div className="w-[17%] font-bold" style={getGradingStyle(selection)}>
        {getGrading(selection)}
      </div>
    </div>
  );
};

function getImageLabel(index: number): string {
  const numberAsText = (index + 1).toString().padStart(2, '0');
  return `DICOM ${numberAsText}`;
}

function getGrading(selection: UserSelection | null) {
  return selection?.grading ? GradingConfig[selection.grading].label : 'TR -';
}

function getGradingStyle(selection: UserSelection | null) {
  if (selection?.grading) {
    return {
      color: GradingConfig[selection.grading].color,
    };
  }
  return {};
}
