import React from 'react';

interface PillProps {
  text?: string;
  className?: string;
}

export const Pill: React.FC<PillProps> = ({ text, className }) => {
  return (
    <span
      className={`flex rounded-[99px] text-neutral-900 text-caption ${className} py-1 px-5 w-fit `}
    >
      {text}
    </span>
  );
};
