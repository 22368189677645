import React, { useEffect, useState } from 'react';
import { ImageContainer } from './ImageContainer';
import { GameStatus } from '@entities/GameStatus';
import { GameSession, UserSelection } from '@entities/GameSession';
import { ButtonBar } from './ButtonBar';
import { TiRadsGrading } from '@entities/TiRadsGrading';
import { SelectionView } from './SelectionView';
import { Timer } from '../Components/Timer';
import { Track } from '../Components/Track';
import { StarsBackground } from '../Components/StarsBackground';
import { calculateScore } from '@utilities/ScoreCalculator';
import { ScoreParticles } from '../Components/ScoreParticles';
import { AnimatePresence } from 'framer-motion';
import { useSound } from 'use-sound';
import winSound from 'src/assets/sounds/SoundFX2-Win.mp3';

interface GameControllerProps {
  gameSession: GameSession;
  setGameStatus: React.Dispatch<React.SetStateAction<GameStatus>>;
  setGameSession: React.Dispatch<React.SetStateAction<GameSession>>;
}

export const GameController: React.FC<GameControllerProps> = ({
  gameSession,
  setGameStatus,
  setGameSession,
}) => {
  const [gameStartTime, setGameStartTime] = useState<Date>();
  const [imageStartTime, setImageStartTime] = useState<Date>();
  const [selectionHistory, setSelectionHistory] = useState<UserSelection[]>([]);
  const [lastSelected, setLastSelected] = useState<TiRadsGrading>();
  const [totalScore, setTotalScore] = useState(0);
  const [lastScore, setLastScore] = useState<number>();
  const [showTimer, setShowTimer] = useState(true);
  const [play] = useSound(winSound, { interrupt: true });

  const currentIndex = selectionHistory.length;
  const currentImage = gameSession.shownImages[currentIndex];

  const resetGameStartTime = () => {
    setGameStartTime(new Date());
  };

  const onSelect = (grading: TiRadsGrading | undefined) => {
    if (!currentImage || !imageStartTime) {
      return;
    }
    setLastSelected(grading);
    const selectedTime = new Date();
    const usedTime = selectedTime.getTime() - imageStartTime.getTime();
    const score = calculateScore(usedTime, grading, currentImage);
    if (score > 0) {
      setTimeout(() => play(), 300);
    }

    requestAnimationFrame(() => {
      setSelectionHistory([
        ...selectionHistory,
        { dicomId: currentImage.id, grading, usedTime },
      ]);
      setLastScore(score);
      setTotalScore(totalScore + score);
      setImageStartTime(selectedTime);
    });
  };

  useEffect(() => {
    resetGameStartTime();
    setImageStartTime(new Date());
  }, []);

  useEffect(() => {
    if (!currentImage) {
      const timeout = setTimeout(() => {
        setGameStatus(GameStatus.Finished);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [currentImage, setGameStatus, setGameSession]);

  useEffect(() => {
    setGameSession(gameSession => {
      return {
        ...gameSession,
        selectionHistory: selectionHistory,
        score: totalScore,
        totalTime: selectionHistory.reduce((sum, selection) => {
          return sum + selection.usedTime;
        }, 0),
      };
    });
  }, [setGameSession, selectionHistory, totalScore]);

  useEffect(() => {
    if (lastScore) {
      setShowTimer(false);
      const timeout = setTimeout(() => {
        setShowTimer(true);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [lastScore]);

  return (
    <div className="h-full w-screen fixed">
      <StarsBackground />
      {imageStartTime && (
        <SelectionView
          currentIndex={currentIndex}
          selectionHistory={selectionHistory}
          startTime={imageStartTime}
        />
      )}
      <ImageContainer image={currentImage} lastSelected={lastSelected} />
      {!!gameStartTime && (
        <ButtonBar
          onSelect={onSelect}
          totalScore={totalScore}
          startTime={gameStartTime}
          isActive={!!currentImage}
          correctAnswer={currentImage?.groundTruth?.grading}
        />
      )}
      <div className="fixed top-0 bottom-[15%] left-1/2 -translate-x-1/2">
        <Track lastSelected={lastSelected} currentIndex={currentIndex} />
      </div>
      <AnimatePresence>
        {!!imageStartTime && !!currentImage && showTimer && (
          <Timer
            startTime={imageStartTime}
            onLimitReached={() => onSelect(undefined)}
          />
        )}
      </AnimatePresence>
      {!!lastScore && <ScoreParticles score={lastScore} key={currentIndex} />}
    </div>
  );
};
