import { GameSession } from '@entities/GameSession';
import { PlayerInfo } from '@entities/PlayerInfo';

export function sortLeaderboard(playerInfo: PlayerInfo[]) {
  return [...playerInfo].sort((a, b) => {
    const scoreComparison = b.score - a.score;

    // If scores are equal, secondary comparison on totalTime
    if (scoreComparison === 0) {
      return a.totalTime - b.totalTime;
    }
    return scoreComparison;
  });
}

export function getNewPlayerRank(
  { score, totalTime }: GameSession,
  playerInfo: PlayerInfo[]
) {
  const newPlayerInfo: PlayerInfo = {
    score,
    totalTime,
    id: '',
    firstName: '',
    lastName: '',
    institution: '',
    email: '',
    selectionHistory: [],
  };
  const newLeaderboard = [...playerInfo, newPlayerInfo];
  const sortedNewLeaderboard = sortLeaderboard(newLeaderboard);
  return sortedNewLeaderboard.indexOf(newPlayerInfo) + 1;
}
