import React, { useState } from 'react';
import { GameStatus } from '@interfaces/GameStatus';
import { GameSession } from '@entities/GameSession';
import Seemode from 'src/assets/images/seemode.svg';
import { Icon } from '../../Components/Icon';
import { ResultTableRow } from './ResultTableRow';
import { formatMilliseconds } from '@utilities/TimeFormat';
import { ResultDetail } from './ResultDetail';
import {
  Frame,
  FrameButton,
  FrameContent,
  FrameFooter,
  FrameHeader,
} from 'src/app/Components/Frame';

export interface ResultProps {
  setGameStatus: React.Dispatch<React.SetStateAction<GameStatus>>;
  gameSession: GameSession;
}

export const Result = ({ setGameStatus, gameSession }: ResultProps) => {
  const [imageId, setImageId] = useState<undefined | string>(undefined);

  const numOfCorrectSelection = gameSession.shownImages.filter(image => {
    const userSelection = gameSession.selectionHistory.find(
      history => history.dicomId == image.id
    );
    return userSelection?.grading === image.groundTruth?.grading;
  }).length;

  const numOfCorrectModelSelection = gameSession.shownImages.filter(
    image =>
      image.modelResult?.findings[0].grading === image.groundTruth?.grading
  ).length;

  const totalUsedTimeModel = gameSession.shownImages.reduce((sum, image) => {
    return sum + (image.modelResult?.usedTime || 0);
  }, 0);

  return (
    <Frame>
      {imageId ? (
        <ResultDetail
          gameSession={gameSession}
          setImageId={setImageId}
          imageId={imageId}
        />
      ) : (
        <>
          <FrameHeader>
            WOW! You collected
            <Icon
              name="star"
              className="text-primary w-[44px] h-[44px] mr-3 ml-7"
            />
            <span className="text-primary">{gameSession.score} Points</span>
          </FrameHeader>
          <FrameContent>
            <div className="grid gap-4 grid-cols-result">
              <div></div>
              <div className="bg-neutral-400 rounded-t-lg text-center flex items-center justify-center">
                <img src={Seemode} className="inline" />
              </div>
              <div className="bg-primary rounded-t-lg text-neutral-900 font-bold text-display6 flex items-center justify-center flex-col">
                <Icon
                  name="consensus"
                  className="text-black w-[24px] h-[24px] mt-2"
                />
                Consensus
              </div>
              <div className="bg-neutral-400 rounded-t-lg  text-primary font-bold text-display2 flex items-center justify-center">
                <Icon name="star" className="text-primary w-[32px] h-[32px]" />
                <span className="mx-5">You</span>
                <Icon name="star" className="text-primary w-[32px] h-[32px]" />
              </div>
              <div></div>
            </div>

            <div className="overflow-y-auto max-h-[40vh] mt-4 2xl:max-h-[40vh]">
              {gameSession.shownImages.map((image, index) => {
                return (
                  <ResultTableRow
                    key={image.id}
                    image={image}
                    index={index + 1}
                    selectionHistory={gameSession.selectionHistory}
                    setImageId={setImageId}
                  />
                );
              })}
            </div>

            <div className="grid gap-4 mt-4 grid-cols-result ">
              <div></div>
              <div className="bg-neutral-500 border-neutral-400 border-2 grid grid-cols-[2fr_1fr] gap-3 justify-items-center align-middle h-[48px]">
                <div className="flex items-center justify-self-start pl-8 gap-x-[4px]">
                  <Icon
                    name="clock"
                    className="text-neutral-300 w-[32px] h-[32px]"
                  />{' '}
                  <span className="text-display2">
                    {formatMilliseconds(totalUsedTimeModel)}
                  </span>
                </div>
                <div className="flex items-center gap-x-[8px]">
                  <span className="text-display2">
                    {numOfCorrectModelSelection}
                  </span>
                  <Icon
                    name="check"
                    className="text-neutral-50 w-[32px] h-[32px]"
                  />
                </div>
              </div>
              <div></div>
              <div className="row-span-2">
                <div className="bg-neutral-500 border-neutral-400 border-2 grid grid-cols-[1fr_2fr] gap-3 justify-items-center align-middle h-[48px]">
                  <div className="flex items-center gap-x-[8px]">
                    <span className="text-display2">
                      {numOfCorrectSelection}
                    </span>
                    <Icon
                      name="check"
                      className="text-neutral-50 w-[32px] h-[32px]"
                    />
                  </div>
                  <div className="flex items-center justify-self-end pr-8 gap-x-[4px]">
                    <Icon
                      name="clock"
                      className="text-neutral-300 w-[32px] h-[32px]"
                    />
                    <span className="text-primary text-display2">
                      {formatMilliseconds(gameSession.totalTime)}
                    </span>
                  </div>
                </div>
                <div className="flex items-center flex-row-reverse mr-8 mt-4">
                  <span className="text-display2 text-primary pl-3">
                    {gameSession.score}
                  </span>
                  <Icon
                    name="star"
                    className="text-primary w-[32px] h-[32px]"
                  />
                </div>
              </div>
              <div></div>
            </div>
          </FrameContent>

          <FrameFooter>
            <FrameButton
              variant="primary"
              onClick={() => setGameStatus(GameStatus.PlayerDetails)}
            >
              <Icon
                name="trophy-wire-frame"
                className="text-neutral-900 w-[24px] h-[24px]"
              />
              View Ranking
            </FrameButton>
          </FrameFooter>
        </>
      )}
    </Frame>
  );
};
