import { NoduleImageData } from '@entities/ImageData';
import { motion, useWillChange } from 'framer-motion';
import React from 'react';

interface DicomImageProps {
  image: NoduleImageData;
  delayBoundingBox?: boolean;
}

export const DicomImage: React.FC<DicomImageProps> = ({
  image,
  delayBoundingBox = false,
}) => {
  const imgRef = React.useRef<HTMLImageElement>(null);
  const [boxStyle, setBoxStyle] = React.useState<React.CSSProperties | null>(
    null
  );
  const willChange = useWillChange();
  const { boundingBox } = image;

  const handleLoad = () => {
    if (imgRef.current && boundingBox) {
      const widthPercentage = (value: number) =>
        (value / imgRef.current!.naturalWidth) * 100 + '%';
      const heightPercentage = (value: number) =>
        (value / imgRef.current!.naturalHeight) * 100 + '%';

      setBoxStyle({
        top: heightPercentage(boundingBox.yMin),
        left: widthPercentage(boundingBox.xMin),
        width: widthPercentage(boundingBox.xMax - boundingBox.xMin),
        height: heightPercentage(boundingBox.yMax - boundingBox.yMin),
      });
    }
  };
  return (
    <div className="relative h-full">
      <img
        ref={imgRef}
        src={image.imageSrc}
        className="h-full"
        onLoad={handleLoad}
      />
      {boxStyle && (
        <motion.svg
          style={{ willChange }}
          viewBox="0 0 40 40"
          preserveAspectRatio="none"
          className="absolute"
          initial={{
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: 0,
          }}
          animate={{
            top: boxStyle.top,
            left: boxStyle.left,
            width: boxStyle.width,
            height: boxStyle.height,
            opacity: 1,
            transition: {
              duration: 1.2,
              delay: delayBoundingBox ? 0.8 : 0,
              ease: 'easeIn',
            },
          }}
        >
          <rect width="40" height="40" className="marching-rect" />
        </motion.svg>
      )}
    </div>
  );
};
