import { GameStatus } from '@entities/GameStatus';
import { useSound } from 'use-sound';
import menuSound from 'src/assets/sounds/BGsoundscape1.mp3';
import gameSound from 'src/assets/sounds/BGsoundscape2.mp3';
import { useEffect } from 'react';
import { Howler } from 'howler';

export function useGameMusic(gameStatus: GameStatus, soundEnabled: boolean) {
  const [playMenuSound, { stop: stopMenuSound }] = useSound(menuSound, {
    interrupt: true,
  });
  const [playGameSound, { stop: stopGameSound }] = useSound(gameSound, {
    interrupt: true,
  });
  const musicType = getMusicType(gameStatus);

  useEffect(() => {
    if (musicType === 'game') {
      stopMenuSound();
      playGameSound();
    } else if (musicType === 'menu') {
      stopGameSound();
      playMenuSound();
    } else {
      stopGameSound();
      stopMenuSound();
    }
  }, [musicType, playGameSound, playMenuSound, stopGameSound, stopMenuSound]);

  useEffect(() => {
    Howler.mute(!soundEnabled);
  }, [soundEnabled]);
}

function getMusicType(gameStatus: GameStatus): 'none' | 'game' | 'menu' {
  if (gameStatus === GameStatus.Title) {
    return 'none';
  } else if (gameStatus === GameStatus.Started) {
    return 'game';
  } else {
    return 'menu';
  }
}
