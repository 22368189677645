import { GradingKeys, TiRadsGrading } from '@entities/TiRadsGrading';
import React from 'react';

interface TrackProps {
  lastSelected: TiRadsGrading | undefined;
  currentIndex: number;
}

export const Track: React.FC<TrackProps> = ({ lastSelected, currentIndex }) => {
  return (
    <div className="h-full flex gap-8">
      {GradingKeys.map(grading => (
        <div
          className="overflow-visible aspect-[64/847] relative"
          key={grading}
        >
          <TrackLine
            grading={grading}
            selected={lastSelected === grading}
            currentIndex={currentIndex}
          />
        </div>
      ))}
    </div>
  );
};

interface TrackLineProps {
  grading: TiRadsGrading;
  selected: boolean;
  currentIndex: number;
}

const TrackLine: React.FC<TrackLineProps> = ({
  grading,
  selected,
  currentIndex,
}) => {
  switch (grading) {
    case TiRadsGrading.TR1:
      return (
        <TrackLineSVG
          id="line-1"
          viewBox="0 0 84 847"
          path="M84.0001 0H61.2699V345.514C61.2699 514.543 40.6953 682.943 6.10352e-05 847H62.5694L84.0001 600V0Z"
          animationDelay={4}
          selectedColor="#c9d4da"
          selected={selected}
          currentIndex={currentIndex}
        />
      );

    case TiRadsGrading.TR2:
      return (
        <TrackLineSVG
          id="line-2"
          viewBox="0 0 64 847"
          path="M64 0H40.5636V437.977C40.5636 575.322 26.9762 712.33 0 847H62.5394L64 394.55V0Z"
          animationDelay={1}
          selectedColor="#ffd990"
          selected={selected}
          currentIndex={currentIndex}
        />
      );

    case TiRadsGrading.TR3:
      return (
        <TrackLineSVG
          id="line-3"
          viewBox="0 0 64 847"
          path="M20.296 0H43.6373L44.1364 590L64 847H0L20.296 590V0Z"
          animationDelay={3}
          className="left-1/2 -translate-x-1/2"
          selectedColor="#ffa048"
          selected={selected}
          currentIndex={currentIndex}
        />
      );

    case TiRadsGrading.TR4:
      return (
        <TrackLineSVG
          id="line-4"
          viewBox="0 0 64 847"
          path="M0 0H23.4364V437.977C23.4364 575.322 37.0238 712.33 64 847H1.46064L0 394.55V0Z"
          animationDelay={0}
          className="right-0"
          selectedColor="#f85e1d"
          selected={selected}
          currentIndex={currentIndex}
        />
      );

    case TiRadsGrading.TR5:
      return (
        <TrackLineSVG
          id="line-5"
          viewBox="0 0 84 847"
          path="M-6.10352e-05 0H22.7301V345.514C22.7301 514.543 43.3047 682.943 83.9999 847H21.4306L-6.10352e-05 600V0Z"
          animationDelay={2}
          className="right-0"
          selectedColor="#e62c4c"
          selected={selected}
          currentIndex={currentIndex}
        />
      );
  }
};

interface TrackLineSVGProps {
  path: string;
  viewBox: string;
  id: string;
  animationDelay: number;
  className?: string;
  selected?: boolean;
  selectedColor: string;
  currentIndex: number;
}

const TrackLineSVG: React.FC<TrackLineSVGProps> = ({
  path,
  viewBox,
  id,
  animationDelay,
  className = '',
  selected,
  selectedColor,
  currentIndex,
}) => {
  return (
    <>
      <svg
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`h-full absolute ${className}`}
      >
        <defs>
          <TrackLineGradient id={id} begin={`${animationDelay}s`} />
        </defs>
        <path d={path} fill={`url(#${id})`} />
      </svg>
      {selected && (
        <svg
          viewBox={viewBox}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`h-full absolute ${className}`}
          key={currentIndex}
        >
          <defs>
            <SelectedGradient id={id} selectedColor={selectedColor} />
          </defs>
          <path d={path} fill={`url(#${id + '-selected'})`} />
        </svg>
      )}
    </>
  );
};

interface TrackLineGradientProps {
  id: string;
  begin: string;
}

const TrackLineGradient: React.FC<TrackLineGradientProps> = ({ id, begin }) => {
  return (
    <linearGradient
      id={id}
      x1="0%"
      y1="0%"
      x2="0%"
      y2="100%"
      spreadMethod="repeat"
    >
      <stop offset="0%" stopColor="#3A4E5A"></stop>
      <stop offset="30%" stopColor="#3A4E5A"></stop>
      <stop offset="45%" stopColor="#61717A">
        <animate
          attributeName="stop-color"
          values="#3A4E5A;#61717A;"
          dur="2s"
          repeatCount="1"
        />
      </stop>
      <stop offset="49%" stopColor="#75838B">
        <animate
          attributeName="stop-color"
          values="#3A4E5A;#75838B;"
          dur="2s"
          repeatCount="1"
        />
      </stop>
      <stop offset="51%" stopColor="#75838B">
        <animate
          attributeName="stop-color"
          values="#3A4E5A;#75838B;"
          dur="2s"
          repeatCount="1"
        />
      </stop>
      <stop offset="55%" stopColor="#61717A">
        <animate
          attributeName="stop-color"
          values="#3A4E5A;#61717A;"
          dur="2s"
          repeatCount="1"
        />
      </stop>
      <stop offset="70%" stopColor="#3A4E5A"></stop>
      <stop offset="100%" stopColor="#3A4E5A"></stop>
      <animate
        attributeName="y1"
        values="0;1;"
        dur="5s"
        begin={begin}
        repeatCount="indefinite"
      />
      <animate
        attributeName="y2"
        values="1;2;"
        dur="5s"
        begin={begin}
        repeatCount="indefinite"
      />
    </linearGradient>
  );
};

interface SelectedGradientProps {
  id: string;
  selectedColor: string;
}

const SelectedGradient: React.FC<SelectedGradientProps> = ({
  id,
  selectedColor,
}) => {
  return (
    <linearGradient id={id + '-selected'} x1="0%" y1="0%" x2="0%" y2="100%">
      <stop offset="0%" stopColor={selectedColor} stopOpacity={0}></stop>
      <stop offset="50%" stopColor={selectedColor} stopOpacity={0}>
        <animate
          attributeName="stop-opacity"
          values=".5;1;.5;0;"
          dur=".9s"
          repeatCount="1"
        />
      </stop>
      <stop offset="100%" stopColor={selectedColor} stopOpacity={0}>
        <animate
          attributeName="stop-opacity"
          values=".5;1;.5;0;"
          dur=".9s"
          repeatCount="1"
        />
      </stop>
      <animate attributeName="y1" values="1;0;" dur=".3s" repeatCount="1" />
    </linearGradient>
  );
};
