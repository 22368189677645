import React, { useEffect, useMemo, useState } from 'react';
import { GameStatus } from '@interfaces/GameStatus';
import { getPlayerInfo, playerInfoKey } from '@utilities/LocalStorage';
import {
  Frame,
  FrameButton,
  FrameContent,
  FrameFooter,
  FrameHeader,
} from '../Components/Frame';
import { Icon } from '../Components/Icon';
import { useQuery } from '@tanstack/react-query';
import { formatMilliseconds } from '@utilities/TimeFormat';
import { sortLeaderboard } from '@utilities/LeaderboardRank';
import { RankConfig } from 'src/config/GameConfig';
import trophy from 'src/assets/images/trophy.svg';
import { PlayerInfo } from '@entities/PlayerInfo';
import { formatToCSV } from '@utilities/CSVEncoder';

export interface LeaderboardProps {
  setGameStatus: React.Dispatch<React.SetStateAction<GameStatus>>;
}

export const Leaderboard = ({ setGameStatus }: LeaderboardProps) => {
  const [downloadMode, setDownloadMode] = useState(false);
  const { data: playerInfo = [] } = useQuery({
    queryKey: [playerInfoKey],
    queryFn: getPlayerInfo,
  });

  const sortedPlayerInfo = sortLeaderboard(playerInfo);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if ((e.key === 'd' || e.key === 'D') && e.ctrlKey && e.shiftKey) {
        setDownloadMode(downloadMode => !downloadMode);
      }
    };

    document.addEventListener('keypress', handleKeyPress);

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  return (
    <Frame>
      <FrameHeader variant="primary">
        <Icon name="trophy" />
        <span className="mx-10">Leaderboard</span>
        <Icon name="trophy" />
      </FrameHeader>
      <FrameContent>
        <div className="flex justify-between flex-col h-full overflow-y-auto overflow-x-hidden">
          <div className="flex flex-col">
            <div className="sm:-mx-2 lg:-mx-4">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-4">
                <div className="overflow-hidden w-full">
                  <ol className="text-left w-full">
                    {sortedPlayerInfo.map((player, i) => {
                      return (
                        <li
                          className="rounded-full text-display4 hover:bg-neutral-400 hover:text-primary flex items-center w-full h-24"
                          key={player.id}
                        >
                          <div className="w-1/12 px-6 py-4 font-medium">
                            <span
                              className="inline-block ml-2"
                              style={{
                                color: RankConfig[i + 1]?.color ?? '#C9D4DA',
                              }}
                            >
                              {(i + 1).toString().padStart(2, '0')}
                            </span>
                          </div>
                          <div className="w-4/12 px-6 py-4 font-semibold hover:text-primary">
                            {player.firstName} {player.lastName}
                          </div>
                          <div className="w-3/12 px-6 py-4 font-medium">
                            <div className="flex gap-1 items-center">
                              <Icon name="clock" className="h-11" />
                              <span className="text-neutral-300">
                                {formatMilliseconds(player.totalTime)}
                              </span>
                            </div>
                          </div>
                          <div className="w-3/12 px-6 py-4 font-medium">
                            <div className="flex gap-2 items-center">
                              <Icon name="star" />
                              <span className="text-neutral-300">
                                {player.score} Points
                              </span>
                            </div>
                          </div>
                          <div className="w-1/12">{renderTrophy(i + 1)}</div>
                        </li>
                      );
                    })}
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FrameContent>
      <FrameFooter>
        <FrameButton onClick={() => setGameStatus(GameStatus.Title)}>
          <Icon name="arrow-left" />
          Back
        </FrameButton>
        <FrameButton
          variant="primary"
          onClick={() => setGameStatus(GameStatus.Ready)}
        >
          <Icon name="controller" />
          Start
        </FrameButton>
        {downloadMode && <PlayerInfoDownload playerInfo={playerInfo} />}
      </FrameFooter>
    </Frame>
  );
};

function renderTrophy(rank: number) {
  const image = rank <= 3 ? RankConfig[rank].image : trophy;
  return <img src={image} alt="trophy" className="max-h-20" />;
}

interface PlayerInfoDownloadProps {
  playerInfo: PlayerInfo[];
}

const PlayerInfoDownload: React.FC<PlayerInfoDownloadProps> = ({
  playerInfo,
}) => {
  const blobURL = useMemo(() => {
    const csv = formatToCSV(playerInfo);
    const blob = new Blob([csv], { type: 'text/csv' });
    return URL.createObjectURL(blob);
  }, [playerInfo]);

  return (
    <a
      href={blobURL}
      download="playerInfo.csv"
      className="rounded-xl py-4 px-5 font-bold ease-out hover:bg-neutral-300 text-neutral-50 hover:text-neutral-900"
    >
      Download CSV
    </a>
  );
};
