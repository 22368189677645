import {
  noduleCompositionToString,
  noduleEchogenicFociToString,
  noduleEchogenicityToString,
  noduleMarginToString,
  noduleShapeToString,
} from '@entities/NoduleCharacteristics';
import { BoundingBox, Nodule, NoduleImageData } from '@interfaces/ImageData';
import { TiRadsGrading } from '@interfaces/TiRadsGrading';
import imageData from 'static/rsna_data_output/all_image_data.json';

export const importData = () => {
  type ImageDataKey = keyof typeof imageData;
  const decodedImageData = Object.keys(imageData).map(key => {
    return decodeImageData(imageData[key as ImageDataKey]);
  });

  return shuffle(decodedImageData);
};

export const decodeImageData = (data: any): NoduleImageData => {
  const characteristics = data.ground_truth.characteristics;
  const usedTime = generateModelResultUsedTime();
  return {
    id: data.id,
    imageSrc: '/rsna_data_output/' + data.image_src,
    boundingBox: decodeBoundingBoxes(data.bounding_boxes),
    modelResult: { findings: decodeModelResult(data.model_result), usedTime },
    groundTruth: {
      characteristics: decodeCharacteristics(characteristics),
      grading: decodeGrading(data.ground_truth.grading),
    },
  };
};

const decodeCharacteristics = (characteristics: any) => {
  return {
    composition: noduleCompositionToString(characteristics.composition),
    echogenicity: noduleEchogenicityToString(characteristics.echogenicity),
    shape: noduleShapeToString(characteristics.shape),
    margin: noduleMarginToString(characteristics.margin),
    echogenicFoci: Array.isArray(characteristics.echogenic_foci)
      ? noduleEchogenicFociToString(characteristics.echogenic_foci)
      : noduleEchogenicFociToString([characteristics.echogenic_foci]),
  };
};

const decodeGrading = (grading: number): TiRadsGrading => {
  switch (grading) {
    case 1:
      return TiRadsGrading.TR1;
    case 2:
      return TiRadsGrading.TR2;
    case 3:
      return TiRadsGrading.TR3;
    case 4:
      return TiRadsGrading.TR4;
    case 5:
      return TiRadsGrading.TR5;
    default:
      console.error(`Cannot decode grading ${grading}`);
      return TiRadsGrading.TR1;
  }
};

const decodeBoundingBoxes = (boundingBoxes: number[]): BoundingBox => {
  return {
    xMin: boundingBoxes[0],
    xMax: boundingBoxes[0] + boundingBoxes[2],
    yMin: boundingBoxes[1],
    yMax: boundingBoxes[1] + boundingBoxes[3],
  };
};

const decodeModelResult = (result: any): Nodule[] => {
  const findings = result.findings;
  if (Array.isArray(findings)) {
    return findings.map((finding: any) => {
      return {
        characteristics: decodeCharacteristics(finding.characteristics),
        grading: decodeGrading(finding.grading),
      };
    });
  } else {
    return [
      {
        characteristics: decodeCharacteristics(findings.characteristics),
        grading: decodeGrading(findings.grading),
      },
    ];
  }
};

const generateModelResultUsedTime = () => {
  // generate model result used time between 200 to 300 milliseconds
  return (
    200 + Math.floor(Math.random() * 10) * 10 + Math.floor(Math.random() * 10)
  );
};

const shuffle = <T>(array: T[]) => {
  return array.sort(() => Math.random() - 0.5);
};
