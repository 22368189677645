import React, { cloneElement } from 'react';

export const iconNames = [
  'arrow-left',
  'clock',
  'check',
  'cross-hair',
  'consensus',
  'controller',
  'star',
  'trophy',
  'trophy-wire-frame',
  'mute',
  'sound',
] as const;
export type Names = (typeof iconNames)[number];

interface IconProps {
  name: Names;
  className?: string;
}

export const Icon: React.FC<IconProps> = ({ className, name }) => {
  const classNames = 'inline-block ' + className;
  return (
    <>
      {cloneElement(getIconForName(name), {
        className: classNames,
      })}
    </>
  );
};

const getIconForName = (name: Names) => {
  switch (name) {
    case 'arrow-left':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.7071 4.29289C13.0976 4.68342 13.0976 5.31658 12.7071 5.70711L6.41421 12L12.7071 18.2929C13.0976 18.6834 13.0976 19.3166 12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929L11.2929 4.29289C11.6834 3.90237 12.3166 3.90237 12.7071 4.29289Z"
            fill="currentColor"
          />
        </svg>
      );
    case 'clock':
      return (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_342_21364)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.0605 2.66677C12.0605 1.96386 12.6304 1.39404 13.3333 1.39404H18.6666C19.3695 1.39404 19.9393 1.96386 19.9393 2.66677C19.9393 3.36968 19.3695 3.9395 18.6666 3.9395H13.3333C12.6304 3.9395 12.0605 3.36968 12.0605 2.66677Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.8997 13.7668C21.3968 14.2638 21.3968 15.0697 20.8997 15.5667L16.8997 19.5667C16.4027 20.0638 15.5969 20.0638 15.0998 19.5667C14.6028 19.0697 14.6028 18.2638 15.0998 17.7668L19.0998 13.7668C19.5969 13.2698 20.4027 13.2698 20.8997 13.7668Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.9999 9.27275C10.8118 9.27275 6.606 13.4786 6.606 18.6667C6.606 23.8548 10.8118 28.0606 15.9999 28.0606C21.1881 28.0606 25.3939 23.8548 25.3939 18.6667C25.3939 13.4786 21.1881 9.27275 15.9999 9.27275ZM4.06055 18.6667C4.06055 12.0727 9.406 6.72729 15.9999 6.72729C22.5939 6.72729 27.9393 12.0727 27.9393 18.6667C27.9393 25.2606 22.5939 30.6061 15.9999 30.6061C9.406 30.6061 4.06055 25.2606 4.06055 18.6667Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_342_21364">
              <rect width="32" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );

    case 'check':
      return (
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.9571 5.79289C21.3476 6.18342 21.3476 6.81658 20.9571 7.20711L9.95711 18.2071C9.56658 18.5976 8.93342 18.5976 8.54289 18.2071L3.54289 13.2071C3.15237 12.8166 3.15237 12.1834 3.54289 11.7929C3.93342 11.4024 4.56658 11.4024 4.95711 11.7929L9.25 16.0858L19.5429 5.79289C19.9334 5.40237 20.5666 5.40237 20.9571 5.79289Z"
            fill="currentColor"
          />
        </svg>
      );

    case 'cross-hair':
      return (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22 12.5H18"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 12.5H2"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 6.5V2.5"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 22.5V18.5"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'consensus':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 2H9C8.44772 2 8 2.44772 8 3V5C8 5.55228 8.44772 6 9 6H15C15.5523 6 16 5.55228 16 5V3C16 2.44772 15.5523 2 15 2Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H8"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 14L11 16L15 12"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'controller':
      return (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.5 11H10.5"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.5 9V13"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.5 12H15.51"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.5 10H18.51"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.82 5H7.18C6.19028 5.00023 5.23579 5.36738 4.50103 6.03046C3.76628 6.69355 3.30345 7.60549 3.202 8.59C3.196 8.642 3.192 8.691 3.185 8.742C3.104 9.416 2.5 14.456 2.5 16C2.5 16.7956 2.81607 17.5587 3.37868 18.1213C3.94129 18.6839 4.70435 19 5.5 19C6.5 19 7 18.5 7.5 18L8.914 16.586C9.28899 16.2109 9.79761 16.0001 10.328 16H14.672C15.2024 16.0001 15.711 16.2109 16.086 16.586L17.5 18C18 18.5 18.5 19 19.5 19C20.2956 19 21.0587 18.6839 21.6213 18.1213C22.1839 17.5587 22.5 16.7956 22.5 16C22.5 14.455 21.896 9.416 21.815 8.742C21.808 8.692 21.804 8.642 21.798 8.591C21.6968 7.60631 21.2341 6.69413 20.4993 6.03083C19.7645 5.36754 18.8099 5.00026 17.82 5Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'star':
      return (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Frame">
            <path
              id="Vector"
              d="M12.3569 2L15.4469 8.26L22.3569 9.27L17.3569 14.14L18.5369 21.02L12.3569 17.77L6.17693 21.02L7.35693 14.14L2.35693 9.27L9.26693 8.26L12.3569 2Z"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      );

    case 'trophy':
      return (
        <svg
          width="35"
          height="36"
          viewBox="0 0 35 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.99993 7.71443C5.35583 7.71443 4.73812 7.97029 4.28267 8.42574C3.82723 8.88118 3.57136 9.4989 3.57136 10.143C3.57136 10.7871 3.82723 11.4048 4.28267 11.8603C4.58173 12.1593 4.95076 12.3724 5.35142 12.4834C5.56783 12.5434 5.78616 12.5875 6.05015 12.6408C6.10231 12.6513 6.15625 12.6622 6.21232 12.6737C6.53081 12.7388 6.90781 12.8202 7.28722 12.9522L9.6611 13.7779C10.2572 13.9852 10.5724 14.6366 10.3651 15.2327C10.1577 15.8289 9.50634 16.1441 8.91019 15.9367L6.53632 15.111C6.31211 15.033 6.06426 14.9764 5.75485 14.9132C5.71083 14.9042 5.66493 14.8949 5.61747 14.8854C5.35426 14.8324 5.04319 14.7698 4.74097 14.6861C3.9635 14.4706 3.24704 14.0571 2.66643 13.4765C1.78233 12.5924 1.28564 11.3933 1.28564 10.143C1.28564 8.89269 1.78233 7.69359 2.66643 6.80949C3.55053 5.92539 4.74962 5.42871 5.99993 5.42871H8.14279C8.77397 5.42871 9.28564 5.94039 9.28564 6.57157C9.28564 7.20275 8.77397 7.71443 8.14279 7.71443H5.99993Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.4286 6.57157C26.4286 5.94039 26.9403 5.42871 27.5715 5.42871H29.7143C30.9646 5.42871 32.1637 5.92539 33.0478 6.80949C33.9319 7.69359 34.4286 8.89269 34.4286 10.143C34.4286 11.3933 33.9319 12.5924 33.0478 13.4765C32.7293 13.795 32.3699 14.0633 31.9819 14.2761C31.4678 14.5581 30.912 14.7702 30.4433 14.949C30.3682 14.9777 30.2954 15.0055 30.2253 15.0325C29.6797 15.2435 29.2521 15.4282 28.8958 15.676L27.0812 16.9383C26.5631 17.2988 25.8509 17.1709 25.4904 16.6528C25.13 16.1346 25.2578 15.4224 25.776 15.062L27.5905 13.7996C28.192 13.3812 28.8475 13.1146 29.4012 12.9006C29.4859 12.8678 29.5681 12.8363 29.6478 12.8058C30.1248 12.623 30.517 12.4727 30.8825 12.2722C31.0823 12.1625 31.2674 12.0244 31.4316 11.8603C31.887 11.4048 32.1429 10.7871 32.1429 10.143C32.1429 9.4989 31.887 8.88118 31.4316 8.42574C30.9761 7.97029 30.3584 7.71443 29.7143 7.71443H27.5715C26.9403 7.71443 26.4286 7.20275 26.4286 6.57157Z"
            fill="currentColor"
          />
          <path
            d="M7.57129 32.2859H28.1427"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.5712 21.4287C16.2024 21.4287 16.7141 21.9404 16.7141 22.5716V25.4863C16.7141 26.8046 15.7128 27.6452 14.9749 28.0152L14.9727 28.0162C14.4339 28.285 13.6921 28.8217 13.0972 29.5233C12.4984 30.2293 12.1426 30.9878 12.1426 31.7144C12.1426 32.3456 11.631 32.8573 10.9998 32.8573C10.3686 32.8573 9.85693 32.3456 9.85693 31.7144C9.85693 30.2488 10.5583 28.9831 11.3539 28.0449C12.1528 27.1028 13.1418 26.3755 13.9513 25.9715C14.1193 25.8871 14.2568 25.7772 14.3412 25.6724C14.4205 25.574 14.4284 25.5143 14.4284 25.4863V22.5716C14.4284 21.9404 14.94 21.4287 15.5712 21.4287Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.4576 20.9016C20.9477 21.0421 21.2854 21.4903 21.2854 22.0001V25.4863C21.2854 25.5143 21.2933 25.574 21.3726 25.6724C21.4569 25.7772 21.5945 25.8871 21.7625 25.9714C22.572 26.3755 23.561 27.1028 24.3599 28.0449C25.1555 28.9831 25.8569 30.2488 25.8569 31.7144C25.8569 32.103 25.6594 32.4649 25.3327 32.6753C25.006 32.8857 24.5948 32.9156 24.2411 32.7548L17.9554 29.8977C17.7171 29.7894 17.5232 29.6025 17.4061 29.3684L16.2632 27.0827C16.0852 26.7266 16.1053 26.3035 16.3163 25.9658L19.1734 21.3944C19.4437 20.9621 19.9676 20.761 20.4576 20.9016ZM18.5943 26.6337L19.2696 27.9843L22.5922 29.4946C22.0015 28.8071 21.2725 28.2813 20.7411 28.0162L20.7389 28.0152C20.0862 27.6879 19.2275 26.9925 19.0379 25.924L18.5943 26.6337Z"
            fill="currentColor"
          />
          <path
            d="M26.4285 3.71436H9.28564V13.7144C9.28564 15.9876 10.1887 18.1678 11.7962 19.7753C13.4036 21.3827 15.5838 22.2858 17.8571 22.2858C20.1304 22.2858 22.3105 21.3827 23.918 19.7753C25.5254 18.1678 26.4285 15.9876 26.4285 13.7144V3.71436Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.1504 25.2179V22.06L16.0527 21.6089H20.564L19.7692 25.2179L20.1429 27.0224L23.2707 27.9247L24.6241 30.6314L25.0752 32.436H11.9925L10.6392 31.9848L11.5414 29.7292L12.4437 27.9247L14.2482 27.0224L15.1504 25.2179Z"
            fill="currentColor"
          />
        </svg>
      );

    case 'trophy-wire-frame':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 9H4.5C3.83696 9 3.20107 8.73661 2.73223 8.26777C2.26339 7.79893 2 7.16304 2 6.5C2 5.83696 2.26339 5.20107 2.73223 4.73223C3.20107 4.26339 3.83696 4 4.5 4H6"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18 9H19.5C20.163 9 20.7989 8.73661 21.2678 8.26777C21.7366 7.79893 22 7.16304 22 6.5C22 5.83696 21.7366 5.20107 21.2678 4.73223C20.7989 4.26339 20.163 4 19.5 4H18"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4 22H20"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 14.6599V16.9999C10 17.5499 9.53 17.9799 9.03 18.2099C7.85 18.7499 7 20.2399 7 21.9999"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 14.6599V16.9999C14 17.5499 14.47 17.9799 14.97 18.2099C16.15 18.7499 17 20.2399 17 21.9999"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18 2H6V9C6 10.5913 6.63214 12.1174 7.75736 13.2426C8.88258 14.3679 10.4087 15 12 15C13.5913 15 15.1174 14.3679 16.2426 13.2426C17.3679 12.1174 18 10.5913 18 9V2Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'mute':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 5L6 9H2V15H6L11 19V5Z"
            stroke="#F2F7FA"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22 9L16 15"
            stroke="#F2F7FA"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 9L22 15"
            stroke="#F2F7FA"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'sound':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 5L6 9H2V15H6L11 19V5Z"
            stroke="#F2F7FA"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.54 8.45996C16.4774 9.3976 17.004 10.6691 17.004 11.995C17.004 13.3208 16.4774 14.5923 15.54 15.53"
            stroke="#F2F7FA"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.0701 4.92993C20.9448 6.80521 21.9979 9.34829 21.9979 11.9999C21.9979 14.6516 20.9448 17.1947 19.0701 19.0699"
            stroke="#F2F7FA"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    default:
      return <></>;
  }
};
