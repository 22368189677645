import React from 'react';
import { Icon } from '../Components/Icon';
import { GameSession } from '@entities/GameSession';
import {
  Frame,
  FrameButton,
  FrameContent,
  FrameFooter,
  FrameHeader,
} from '../Components/Frame';
import { useForm, SubmitHandler } from 'react-hook-form';
import { GameStatus } from '@entities/GameStatus';
import { formatMilliseconds } from '@utilities/TimeFormat';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import {
  getPlayerInfo,
  playerInfoKey,
  savePlayerInfo,
} from '@utilities/LocalStorage';
import { v4 as uuidv4 } from 'uuid';
import { getNewPlayerRank } from '@utilities/LeaderboardRank';
import { RankConfig } from 'src/config/GameConfig';
import trophy from 'src/assets/images/trophy.svg';

interface PlayerDetailsProps {
  setGameStatus: React.Dispatch<React.SetStateAction<GameStatus>>;
  gameSession: GameSession;
}

export const PlayerDetails: React.FC<PlayerDetailsProps> = ({
  gameSession,
  setGameStatus,
}) => {
  const { data: playerInfo = [] } = useQuery({
    queryKey: [playerInfoKey],
    queryFn: getPlayerInfo,
  });
  const rank = getNewPlayerRank(gameSession, playerInfo);
  return (
    <Frame>
      {renderHeader(rank, gameSession.score)}
      <FrameContent>
        <div className="py-[5vh] px-[10vh]">
          <div className="font-semibold text-display4">
            <p className="flex items-center gap-4">
              <Icon name="clock" className="h-10 w-10" />
              <span className="text-primary ">
                Your time: {formatMilliseconds(gameSession.totalTime)}
              </span>
            </p>
            <p className="flex items-center gap-4">
              <Icon name="star" className="h-10 w-10" />
              <span className="text-primary">
                Your score: {gameSession.score} Points
              </span>
            </p>
          </div>
          <PlayerDetailsForm
            gameSession={gameSession}
            setGameStatus={setGameStatus}
          />
          <div className="mt-8 font-light">
            <strong className="font-semibold">Note:</strong> We only display
            your last name and first name on the leaderboard
          </div>
        </div>
      </FrameContent>
      <FrameFooter>
        <FrameButton
          variant="neutral"
          onClick={() => setGameStatus(GameStatus.Finished)}
        >
          <Icon name="arrow-left" />
          Back
        </FrameButton>
        <FrameButton
          variant="neutral"
          onClick={() => setGameStatus(GameStatus.Leaderboard)}
        >
          Cancel
        </FrameButton>
        <FrameButton variant="primary" type="submit" form="player-details-form">
          <Icon name="trophy-wire-frame" />
          Save
        </FrameButton>
      </FrameFooter>
    </Frame>
  );
};

function renderHeader(rank: number, score: number) {
  if (rank <= 3) {
    const image = RankConfig[rank].image;
    return (
      <FrameHeader>
        <img src={image} className="max-h-20" />
        <span className="mx-10 text-primary">
          Congratulations you ranked {RankConfig[rank].label}
        </span>
        <img src={image} className="max-h-20" />
      </FrameHeader>
    );
  } else if (rank < 10) {
    return (
      <FrameHeader>
        <img src={trophy} className="max-h-20" />
        <span className="mx-10 text-primary">
          Congratulations you ranked {rank}th
        </span>
        <img src={trophy} className="max-h-20" />
      </FrameHeader>
    );
  } else {
    return (
      <FrameHeader>
        <img src={trophy} className="max-h-20" />
        <span className="mx-10 flex items-center text-primary">
          You collected <Icon name="star" className="w-16 h-16 mx-4" /> {score}{' '}
          Points
        </span>
        <img src={trophy} className="max-h-20" />
      </FrameHeader>
    );
  }
}

interface FormValues {
  firstName: string;
  lastName?: string;
  institution?: string;
  email?: string;
}

interface PlayerDetailsFormProps {
  gameSession: GameSession;
  setGameStatus: React.Dispatch<React.SetStateAction<GameStatus>>;
}

const PlayerDetailsForm: React.FC<PlayerDetailsFormProps> = ({
  gameSession,
  setGameStatus,
}) => {
  const { score, totalTime, selectionHistory } = gameSession;
  const { register, handleSubmit } = useForm<FormValues>();

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: savePlayerInfo,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [playerInfoKey] });
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async data => {
    await mutation.mutateAsync({
      id: uuidv4(),
      ...data,
      score,
      totalTime,
      selectionHistory,
    });
    setGameStatus(GameStatus.Leaderboard);
  };

  return (
    <form
      className="flex flex-col gap-8 pt-8"
      onSubmit={handleSubmit(onSubmit)}
      id="player-details-form"
    >
      <PlayerDetailsFormInput
        label="First Name *"
        {...register('firstName', { required: true })}
      />
      <PlayerDetailsFormInput label="Last Name" {...register('lastName')} />
      <PlayerDetailsFormInput
        label="Institution Name"
        {...register('institution')}
      />
      <PlayerDetailsFormInput
        label="Email"
        type="email"
        {...register('email')}
      />
    </form>
  );
};

interface PlayerDetailsFormInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const PlayerDetailsFormInput = React.forwardRef<
  HTMLInputElement,
  PlayerDetailsFormInputProps
>(({ label, placeholder: _, ...rest }, ref) => {
  return (
    <input
      className="p-4 bg-neutral-900 placeholder:text-neutral-100 placeholder:font-light"
      type="text"
      placeholder={label}
      autoComplete="off"
      ref={ref}
      {...rest}
    />
  );
});
