import React, { useState } from 'react';
import { Leaderboard } from '@views/Leaderboard';
import { GameStatus } from '@interfaces/GameStatus';
import { Result } from '@views/Result/Result';
import { GameSession } from '@entities/GameSession';
import { imageData } from 'src/config/imageData';
import { GameController } from './GameController';
import { GameInstruction } from './GameInstructions';
import { GameConfig } from 'src/config/GameConfig';
import { PlayerDetails } from './PlayerDetails';
import { ConfettiParticles } from '../Components/ConfettiParticles';
import { TitleScreen } from './TitleScreen';
import { useGameMusic } from '@utilities/GameMusic';
import { FrameButton } from '../Components/Frame';
import { Icon } from '../Components/Icon';

export const GameWrapper = () => {
  const [gameStatus, setGameStatus] = useState(GameStatus.Title);
  const [gameSession, setGameSession] = useState<GameSession>({
    score: 0,
    shownImages: imageData.slice(0, GameConfig.numberOfImages),
    totalTime: 0,
    selectionHistory: [],
  });
  const [soundEnabled, setSoundEnabled] = useState(true);
  useGameMusic(gameStatus, soundEnabled);

  const getContent = () => {
    switch (gameStatus) {
      case GameStatus.Title:
        return <TitleScreen setGameStatus={setGameStatus} />;
      case GameStatus.Paused:
      case GameStatus.Started:
        return (
          <GameController
            gameSession={gameSession}
            setGameStatus={setGameStatus}
            setGameSession={setGameSession}
          />
        );

      case GameStatus.Finished:
        return (
          <Result setGameStatus={setGameStatus} gameSession={gameSession} />
        );

      case GameStatus.Ready:
        return <GameInstruction setGameStatus={setGameStatus} />;
      case GameStatus.PlayerDetails:
        return (
          <PlayerDetails
            setGameStatus={setGameStatus}
            gameSession={gameSession}
          />
        );
      case GameStatus.Leaderboard:
      default:
        return <Leaderboard setGameStatus={setGameStatus} />;
    }
  };

  return (
    <div className="bg-neutral-900 h-screen">
      <div className="mx-auto content-center text-neutral-50 h-full">
        {getContent()}
      </div>
      <div className="fixed top-xxxl right-xxxl z-50 flex gap-6">
        {soundEnabled && (
          <FrameButton onClick={() => setSoundEnabled(false)}>
            <Icon name="mute" />
          </FrameButton>
        )}
        {!soundEnabled && (
          <FrameButton onClick={() => setSoundEnabled(true)}>
            <Icon name="sound" />
          </FrameButton>
        )}
        {gameStatus === GameStatus.Started && (
          <FrameButton onClick={() => setGameStatus(GameStatus.Title)}>
            <Icon name="controller" />
            Exit
          </FrameButton>
        )}
      </div>
      {(gameStatus === GameStatus.Finished ||
        gameStatus === GameStatus.PlayerDetails) && <ConfettiParticles />}
    </div>
  );
};
